import { graphql } from 'gql';

import { client } from './client';

export type { ClubFragment, ClubShortFragment, ClubTeamFragment } from 'gql/graphql';

// FRAGMENTS //////////////////////////////////////////////////////////////////

graphql(`
  fragment ClubTeam on Team {
    id
    name
    players {
      id
      name
      passportName
      image
      primaryPosition {
        name
        wsPosition
      }
      age
      birthDate
      height
      playerReports(orgId: $orgId, common: true, limit: 1) {
        change {
          createdAt
        }
      }
    }
  }
`);

graphql(`
  fragment Club on Club {
    id
    name
    teams {
      ...ClubTeam
    }
  }
`);

graphql(`
  fragment ClubShort on Club {
    id
    name
    teams {
      ...TeamShort
    }
  }
`);

// QUERIES ////////////////////////////////////////////////////////////////////

const CLUB_QUERY_SHORT = graphql(`
  query ClubShort($clubId: ID!) {
    club(id: $clubId) {
      ...ClubShort

      ... on ClubQueryDomainError {
        code
        message
      }
    }
  }
`);

const CLUB_QUERY = graphql(`
  query ClubFull($clubId: ID!, $orgId: ID!) {
    club(id: $clubId) {
      ...Club

      ... on ClubQueryDomainError {
        code
        message
      }
    }
  }
`);

const CLUB_TEAM_QUERY = graphql(`
  query ClubTeam($teamId: ID!, $orgId: ID!) {
    team(id: $teamId) {
      ...ClubTeam

      ... on TeamQueryDomainError {
        code
        message
      }
    }
  }
`);

const clubService = {
  queryClubShort(clubId: string) {
    return client.query({
      query: CLUB_QUERY_SHORT,
      variables: { clubId },
    });
  },

  queryClub: async (clubId: string, orgId: string) => {
    const result = await client.query({
      query: CLUB_QUERY,
      variables: { clubId, orgId },
    });

    if (result.data.club.__typename === 'ClubQueryDomainError') {
      return Promise.reject(result.data.club);
    }

    return result.data.club;
  },

  queryClubTeam: async (teamId: string, orgId: string) => {
    const result = await client.query({
      query: CLUB_TEAM_QUERY,
      variables: { teamId, orgId },
    });

    if (result.data.team.__typename === 'TeamQueryDomainError') {
      return Promise.reject(result.data.team);
    }

    return result.data.team;
  },
};

export default clubService;
