import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/CloseRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import dayjs, { Dayjs } from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import styled from '@emotion/styled/macro';

import CreateTeamDialog from 'features/CreateTeam';
import useNotificationsStore from 'features/Notifications/Notifications.model';
import { FootEnum } from 'shared/api';
import geographyService from 'shared/api/geography';
import playerService, { PlayerFragment } from 'shared/api/player';
import teamService, { TeamShortFragment } from 'shared/api/team';
import DatePicker from 'shared/components/DatePicker';
import { usePlayerStore } from 'widgets/Player/model/Player.model';

interface Props {
  open: boolean;
  team?: TeamShortFragment;
  player?: PlayerFragment;
  onClose: () => void;
  onSubmit: (player: PlayerFragment) => void;
}

type Option = {
  id: string;
  name: string;
};

const schema = yup.object({
  name: yup.string().required(),
  nameRu: yup.string().required(),
  passportName: yup.string(),
  birthDate: yup
    .mixed<Dayjs>()
    .test('date-test', 'Invalid date', (value) => {
      if (!value || !dayjs(value).isValid()) {
        return false;
      }
      return true;
    })
    .required(),
  currentTeam: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required(),
  birthCountry: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .nullable(),
  birthCity: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .nullable(),
  nationalTeamCountry: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .nullable(),
  startedFootballAt: yup.number().nullable(),
  foot: yup.string().nullable(),
  tmUrl: yup.string().nullable(),
  mffId: yup.string().nullable(),
});

export type FormData = yup.InferType<typeof schema>;

const PlayerForm = ({ open, team, player, onClose, onSubmit }: Props) => {
  const notification = useNotificationsStore();
  const { updatePlayer } = usePlayerStore();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: {
      name: player?.nameEn || player?.nameRu || '',
      nameRu: player?.nameRu || '',
      passportName: player?.passportName || '',
      birthDate: player?.birthDate ? dayjs(player.birthDate) : null,
      birthCountry: player?.birthCountry || { id: '', name: '' },
      birthCity: player?.birthCity || { id: '', name: '' },
      currentTeam: player?.currentTeam || team || { id: '', name: '' },
      nationalTeamCountry: player?.nationalTeamCountry || { id: '', name: '' },
      foot: player?.foot || '',
      tmUrl: player?.tmUrl || '',
      mffId: player?.mffId || '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      name: player?.nameEn || player?.nameRu || '',
      nameRu: player?.nameRu || '',
      passportName: player?.passportName || '',
      birthDate: player?.birthDate ? dayjs(player.birthDate) : null,
      birthCountry: player?.birthCountry || null,
      birthCity: player?.birthCity || null,
      currentTeam: player?.currentTeam || team || null,
      nationalTeamCountry: player?.nationalTeamCountry || null,
      foot: player?.foot || null,
      tmUrl: player?.tmUrl || null,
      mffId: player?.mffId || null,
    });
  }, [player, reset, team]);

  const [teamsLoading, setTeamsLoading] = useState(false);
  const [countriesLoading, setCountriesLoading] = useState(false);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [nationalTeamsLoading, setNationalTeamsLoading] = useState(false);

  const [teamsSearchResult, setTeamsSearchResult] = useState<Option[]>([]);
  const [countriesSearchResult, setCountriesSearchResult] = useState<Option[]>([]);
  const [citiesSearchResult, setCitiesSearchResult] = useState<Option[]>([]);
  const [nationalTeamsSearchResult, setNationalTeamsSearchResult] = useState<Option[]>([]);

  const [createTeamDialogVisibility, setCreateTeamDialogVisibility] = useState(false);
  const [sending, setSending] = useState(false);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleCreate = async (data: FormData) => {
    try {
      setSending(true);
      const result = await playerService.createPlayer({
        name: data.name,
        nameRu: data.nameRu,
        passportName: data.passportName,
        birthDate: dayjs(getValues('birthDate')).format('YYYY-MM-DD'),
        birthCountryId: data.birthCountry?.id,
        birthCityId: data.birthCity?.id,
        currentTeamId: data.currentTeam?.id,
        nationalTeamCountryId: data.nationalTeamCountry?.id,
        startedFootballAt: data.startedFootballAt,
        foot: data.foot as FootEnum,
        tmId: data.tmUrl?.split('/').pop() || null,
        mffId: data.mffId,
      });

      onSubmit(result);
      setSending(false);
      handleClose();
      notification.send({ title: 'Игрок успешно создан', type: 'success' });
    } catch (exx) {
      console.error(exx);
      notification.send({ title: 'Ошибка при создании игрока', type: 'error', error: exx });
      setSending(false);
    }
  };

  const handleUpdate = async (data: FormData) => {
    try {
      setSending(true);
      await updatePlayer({
        id: player.id,
        name: data.name,
        passportName: data.passportName,
        nameRu: data.nameRu,
        birthDate: dayjs(getValues('birthDate')).format('YYYY-MM-DD'),
        birthCountryId: data.birthCountry?.id,
        birthCityId: data.birthCity?.id,
        currentTeamId: data.currentTeam?.id,
        nationalTeamCountryId: data.nationalTeamCountry?.id,
        startedFootballAt: data.startedFootballAt,
        foot: data.foot as FootEnum,
        tmId: data.tmUrl?.split('/').pop() || null,
        mffId: data.mffId,
      });
      setSending(false);
      handleClose();
      notification.send({ title: 'Игрок успешно обновлен', type: 'success' });
    } catch (exx) {
      console.error(exx);
      notification.send({ title: 'Ошибка при обновлении игрока', type: 'error', error: exx });
      setSending(false);
    }
  };

  const searchTeams = useMemo(() => {
    return debounce(async (value: string) => {
      try {
        setTeamsLoading(true);
        const result = await teamService.searchTeams({ query: value, first: 20 });

        setTeamsLoading(false);
        setTeamsSearchResult(result);
      } catch (exx) {
        setTeamsLoading(false);
        return [];
      }
    }, 100);
  }, []);

  const searchCountries = useMemo(() => {
    return debounce(async (value: string) => {
      try {
        setCountriesLoading(true);
        const result = await geographyService.searchCountries(value);

        setCountriesLoading(false);
        setCountriesSearchResult(result);
      } catch (exx) {
        setCountriesLoading(false);
        return [];
      }
    }, 100);
  }, []);

  const searchNationalTeams = useMemo(() => {
    return debounce(async (value: string) => {
      try {
        setNationalTeamsLoading(true);
        const result = await geographyService.searchCountries(value);

        setNationalTeamsLoading(false);
        setNationalTeamsSearchResult(result);
      } catch (exx) {
        setNationalTeamsLoading(false);
        return [];
      }
    }, 100);
  }, []);

  const searchCities = useMemo(() => {
    return debounce(async (countryId: string, value: string) => {
      try {
        setCitiesLoading(true);
        const result = await geographyService.searchCities(countryId, value);

        setCitiesLoading(false);
        setCitiesSearchResult(result);
      } catch (exx) {
        setCitiesLoading(false);
        return [];
      }
    }, 100);
  }, []);

  const handleTeamInputChange = (event, value: string) => {
    searchTeams(value);
  };

  const handleCountryInputChange = (event, value: string) => {
    searchCountries(value);
  };

  const handleCityInputChange = (event, value: string) => {
    searchCities(getValues('birthCountry.id'), value);
  };

  const handleNationalTeamInputChange = (event, value: string) => {
    searchNationalTeams(value);
  };

  const handleChangeDate = (date: Dayjs) => {
    setValue('birthDate', date);
    trigger('birthDate');
  };

  const handleTeamChange = (event, option: Option) => {
    setValue('currentTeam', option);
    trigger('currentTeam');
  };

  const handleCreateTeam = (team: TeamShortFragment) => {
    setValue('currentTeam', team);
    trigger('currentTeam');
    setCreateTeamDialogVisibility(false);
  };

  const handleCountryChange = (event, option: Option) => {
    setValue('birthCountry', option);
    trigger('birthCountry');
  };

  const handleCityChange = (event, option: Option) => {
    setValue('birthCity', option);
    trigger('birthCity');
  };

  const handleNationalTeamChange = (event, option: Option) => {
    setValue('nationalTeamCountry', option);
    trigger('nationalTeamCountry');
  };

  const handleFoot = ({ target: { value } }) => {
    setValue('foot', value);
  };

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <form>
          <DialogTitle>
            {!!player ? 'Редактировать игрока' : 'Создать нового игрока'}
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContentStyled>
            <Row>
              <Controller
                name="nameRu"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Имя на русском"
                    fullWidth
                    autoComplete="off"
                    required
                    error={Boolean(errors.nameRu)}
                  />
                )}
              />

              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Имя на английском"
                    fullWidth
                    autoComplete="off"
                    required
                    error={Boolean(errors.name)}
                  />
                )}
              />
            </Row>

            <Row>
              <Controller
                name="passportName"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Полное имя" fullWidth autoComplete="off" />
                )}
              />
            </Row>

            <Row>
              <Controller
                name="birthDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Дата рождения"
                    value={field.value ? dayjs(field.value) : null}
                    onChange={handleChangeDate}
                    required
                    textFieldProps={{
                      error: Boolean(errors.birthDate),
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="currentTeam"
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    options={teamsSearchResult}
                    getOptionLabel={(option) => option?.name}
                    value={field.value}
                    onInputChange={handleTeamInputChange}
                    onChange={handleTeamChange}
                    loading={teamsLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Текущая команда"
                        error={Boolean(errors.currentTeam)}
                        required
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {teamsLoading && <CircularProgress color="inherit" size={20} />}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    noOptionsText={
                      <Button size="small" onClick={() => setCreateTeamDialogVisibility(true)}>
                        Создать
                      </Button>
                    }
                  />
                )}
              />
            </Row>

            <Row>
              <Controller
                control={control}
                name="birthCountry"
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    options={countriesSearchResult}
                    getOptionLabel={(option) => option?.name}
                    value={field.value}
                    onInputChange={handleCountryInputChange}
                    onChange={handleCountryChange}
                    loading={countriesLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Страна рождения"
                        error={Boolean(errors.birthCountry)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {countriesLoading && <CircularProgress color="inherit" size={20} />}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />

              <Controller
                control={control}
                name="birthCity"
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    options={citiesSearchResult}
                    getOptionLabel={(option) => option?.name}
                    value={field.value}
                    onInputChange={handleCityInputChange}
                    onChange={handleCityChange}
                    loading={citiesLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Город рождения"
                        error={Boolean(errors.birthCity)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {citiesLoading && <CircularProgress color="inherit" size={20} />}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Row>

            <Row>
              <Controller
                control={control}
                name="nationalTeamCountry"
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    options={nationalTeamsSearchResult}
                    getOptionLabel={(option) => option?.name}
                    value={field.value}
                    onInputChange={handleNationalTeamInputChange}
                    onChange={handleNationalTeamChange}
                    loading={nationalTeamsLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Выступал за сборную страны"
                        error={Boolean(errors.nationalTeamCountry)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {nationalTeamsLoading && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />

              <Controller
                name="startedFootballAt"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label="Возраст начала занятий футболом"
                    fullWidth
                    error={Boolean(errors.startedFootballAt)}
                  />
                )}
              />
            </Row>

            <Row>
              <Controller
                name="foot"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>Ведущая нога</InputLabel>
                    <Select {...field} label="Ведущая нога" onChange={handleFoot}>
                      <MenuItem value={FootEnum.Left}>Левая</MenuItem>
                      <MenuItem value={FootEnum.Right}>Правая</MenuItem>
                      <MenuItem value={FootEnum.Both}>Амбидекстр</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Row>

            <Divider />

            <Row>
              <Controller
                name="tmUrl"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Ссылка на Transfermarkt"
                    fullWidth
                    autoComplete="off"
                    error={Boolean(errors.tmUrl)}
                  />
                )}
              />
            </Row>

            <Row>
              <Controller
                name="mffId"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Ссылка на MFF"
                    fullWidth
                    autoComplete="off"
                    error={Boolean(errors.mffId)}
                  />
                )}
              />
            </Row>
          </DialogContentStyled>

          <DialogActions>
            <LoadingButton
              loading={sending}
              onClick={handleSubmit(!!player ? handleUpdate : handleCreate)}
              type="submit"
              size="medium"
              variant="contained"
              disabled={!isValid}
            >
              {!!player ? 'Сохранить' : 'Создать'}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      <CreateTeamDialog
        onCreate={handleCreateTeam}
        visible={createTeamDialogVisibility}
        close={() => setCreateTeamDialogVisibility(false)}
      />
    </>
  );
};

export default PlayerForm;

const DialogContentStyled = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  padding-top: 5px !important;
`;

const Row = styled.div`
  display: flex;
  gap: var(--space-md);
`;
