import { useEffect, useState } from 'react';
import { ChevronLeft } from '@mui/icons-material';
import { Chip, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import { Header, Title } from 'app/PageUI';
import useUserStore from 'entities/User.model';
import useNotificationsStore from 'features/Notifications/Notifications.model';
import exercisesService, { ExerciseFragment } from 'shared/api/exercises';
import VideoPlayer from 'shared/components/video-player';
import textWithLineBreaks from 'shared/utils/textWithLineBreaks';
import ExerciseActions from 'widgets/Exercises/ui/ExerciseActions';

const ExercisePage = () => {
  const { exerciseId } = useParams();
  const { currentOrgUser } = useUserStore();
  const notification = useNotificationsStore();
  const navigate = useNavigate();
  const [exercise, setExercise] = useState<ExerciseFragment>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!exerciseId || !currentOrgUser) return;
    fetchExercise();
  }, [exerciseId, currentOrgUser]);

  const fetchExercise = async () => {
    try {
      setLoading(true);
      const result = await exercisesService.queryExercise({
        orgId: currentOrgUser.organization.id,
        ids: [exerciseId],
      });
      setExercise(result);
      setLoading(false);
    } catch (exx) {
      notification.send({ title: 'Не удалось загрузить упражнение', error: exx, type: 'error' });
      console.error(exx);
    }
  };

  const handleUpdate = (exercise: ExerciseFragment) => {
    setExercise(exercise);
  };

  const handleDelete = (id: string) => {
    navigate(`/trainings/exercises`);
  };

  const renderTagsGroup = (title: string, type: string, emoji?: string) => {
    const tags = exercise.tags.filter((tag) => tag.category === type);
    if (!tags.length) return null;
    return (
      <>
        <TagType>{title}</TagType>
        <Tags>
          {tags.map((tag) => (
            <Chip
              key={tag.id}
              label={emoji ? `${emoji} ${tag.name}` : tag.name}
              size="small"
              variant="outlined"
            />
          ))}
        </Tags>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Портал | {loading && !exercise ? 'Упражнение' : exercise?.name}</title>
      </Helmet>
      {loading && !exercise ? (
        <CircularProgress sx={{ margin: '2rem 0' }} />
      ) : (
        <>
          <Header>
            <div>
              <BackLink to="/trainings/exercises">
                <ChevronLeft />
                Все упражнения
              </BackLink>
              <Title>{exercise.name}</Title>
            </div>
            <ExerciseActions exercise={exercise} onUpdate={handleUpdate} onDelete={handleDelete} />
          </Header>
          <Layout>
            <Content>
              <Description>{textWithLineBreaks(exercise.description)}</Description>

              <Separator />

              <div>
                {renderTagsGroup('Возраст', 'age')}
                {renderTagsGroup('Техническая направленность', 'technical', '⚽️')}
                {renderTagsGroup('Тактическая направленность', 'tactical', '⚙️')}
                {renderTagsGroup('Физическая направленность', 'physical', '💪')}
                {renderTagsGroup('Общие характеристики', 'general', '🐴')}
              </div>
            </Content>
            <Attachments>
              {exercise.attachments.map((attachment) => (
                <AttachmentImage key={attachment.id} src={attachment.presignedUrl} />
              ))}

              {exercise.video && (
                <VideoPlayer
                  sources={[exercise.video.transcodedVideoUrl, exercise.video.originalVideoUrl]}
                />
              )}
            </Attachments>
          </Layout>
        </>
      )}
    </>
  );
};

export default ExercisePage;

const BackLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: var(--space-xxs);
  color: var(--primary);
  font-size: var(--fz-sm);
  text-decoration: none;

  svg {
    font-size: var(--fz-sm);
  }

  &:hover {
    color: var(--primary-hover);
    text-decoration: underline;
  }
`;

const Layout = styled.div`
  align-self: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-xl);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: var(--space-md);
    padding: 0 var(--space-md);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-md);

  @media (max-width: 768px) {
    order: 2;
  }
`;

const Description = styled.div`
  max-width: 600px;
  font-size: var(--fz-sm);
  text-wrap: pretty;
  line-height: 1.5;

  p {
    margin: 0;
    overflow-x: hidden;
  }

  p + p {
    margin-top: var(--space-xs);
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--neutral-border-light);
`;

const TagType = styled.h5`
  margin-top: var(--space-md);
  margin-bottom: var(--space-xxs);
  font-size: var(--fz-sm);
  font-weight: 500;
  color: var(--text-subtle);

  &:first-of-type {
    margin-top: 0;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs);
`;

const Attachments = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);

  @media (max-width: 768px) {
    order: 1;
  }
`;

const AttachmentImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: var(--radii-md);
`;
