import { lazy } from 'react';
import { generatePath, Navigate, Route, Routes } from 'react-router-dom';

import Layout from './Layout';
import RequireAuth from 'features/RequireAuth';
import ExercisePage from 'pages/Exercise';

const UsersManagementPage = lazy(() => import('pages/UsersManagement'));
const ClubPage = lazy(() => import('pages/Club'));
const MatchesPage = lazy(() => import('pages/Matches'));
const PlayerPage = lazy(() => import('pages/Player'));
const TrainingPage = lazy(() => import('pages/Training'));
const TrainingsPage = lazy(() => import('pages/Trainings'));
const SignInPage = lazy(() => import('pages/SignIn'));
const MeasurementsPage = lazy(() => import('pages/Measurements'));
const ScoutingPage = lazy(() => import('pages/Scouting'));
const ScoutingTaskPage = lazy(() => import('pages/ScoutingTask'));
const MissingPage = lazy(() => import('pages/MissingPlayer'));
const MissingViewPage = lazy(() => import('pages/MissingPlayerView'));
const SportBudgetPage = lazy(() => import('pages/SportBudget'));

export const routes = {
  common: '/',
  signIn: '/user/sign-in',
  users: '/users',
  club: '/club',
  clubTeam: '/club/team/:teamId',
  measurements: '/measurements',
  missingPlayer: '/missing-player',
  missingPlayerView: '/missing-player/:missingPlayerId',

  matches: '/matches',
  matchesAbsolutePath: '/matches/*',
  match: ':matchId',
  matchLineupEditor: ':matchId/lineup-editor',
  matchTeamsheet: ':matchId/teamsheet',

  player: '/player/:playerId',
  playerAbsolutePath: '/player/:playerId/*',
  playerReviews: '*',
  playerReport: 'report/:reportId',
  playerStats: 'stats',
  playerWork: 'work',

  trainings: '/trainings',
  trainingsAbsolutePath: '/trainings/*',
  trainingSessions: '*',
  trainingExercises: 'exercises',
  trainingSession: '/trainings/session/:trainingId',
  trainingExercise: '/trainings/exercise/:exerciseId',

  scouting: '/scouting',
  scoutingAbsolutePath: '/scouting/*',
  scoutingTasks: '*',
  scoutingLatestReports: 'latest-reports',
  scoutingAssignments: 'assignments',
  scoutingTask: '/scouting/task/:taskId/*',
  scoutingProfile: ':profileId/*',
  scoutingReport: ':reportId',

  sportBudget: '/sports-budget',
  sportBudgetPlannedDeals: 'planned-deals',
  sportBudgetPlannedDeal: ':dealId',
  sportBudgetImpactReports: 'impact-reports',
  sportBudgetImpactReport: ':reportId',
  sportBudgetImpactReportCopy: 'copy/:copyReportId',
  sportBudgetActualDeals: 'actual-deals',
  sportBudgetActualDeal: ':actualDealId',
  sportBudgetExternalPayments: 'external-payments',
  sportBudgetDealExternalPayment: ':externalPaymentId',
  sportBudgetDealExternalPaymentCopy: 'copy/:copyExternalPaymentId',
  sportBudgetLicensings: 'licensing',
  sportBudgetLicensing: ':licensingId',

  sportBudgetAbsolutePath: '/sports-budget/*',
  sportBudgetPlannedDealsAbsolutePath: 'planned-deals/*',
  sportBudgetImpactReportsAbsolutePath: 'impact-reports/*',
  sportBudgetActualDealsAbsolutePath: 'actual-deals/*',
  sportBudgetExternalPaymentsAbsolutePath: 'external-payments/*',
  sportBudgetLicensingAbsolutePath: 'licensing/*',
};

export const Router = () => (
  <Routes>
    <Route path={routes.signIn} element={<SignInPage />} />
    <Route path={routes.common} element={<Layout />}>
      <Route path={routes.users} element={<UsersManagementPage />} />
      <Route
        index
        element={<Navigate to={generatePath(routes.clubTeam, { teamId: '15' })} replace={true} />}
      />
      <Route
        path={routes.club}
        element={<Navigate to={generatePath(routes.clubTeam, { teamId: '15' })} replace={true} />}
      />
      <Route
        path={routes.sportBudget}
        element={<Navigate to={routes.sportBudgetPlannedDeals} replace={true} />}
      />
      <Route
        path={routes.clubTeam}
        element={
          <RequireAuth>
            <ClubPage />
          </RequireAuth>
        }
      />
      <Route
        path={routes.matchesAbsolutePath}
        element={
          <RequireAuth>
            <MatchesPage />
          </RequireAuth>
        }
      />
      <Route
        path={routes.measurements}
        element={
          <RequireAuth>
            <MeasurementsPage />
          </RequireAuth>
        }
      />
      <Route
        path={routes.trainingsAbsolutePath}
        element={
          <RequireAuth>
            <TrainingsPage />
          </RequireAuth>
        }
      />
      <Route
        path={routes.trainingSession}
        element={
          <RequireAuth>
            <TrainingPage />
          </RequireAuth>
        }
      />
      <Route path={routes.trainingExercise} element={<ExercisePage />} />
      <Route
        path={routes.missingPlayer}
        element={
          <RequireAuth>
            <MissingPage />
          </RequireAuth>
        }
      />
      <Route
        path={routes.missingPlayerView}
        element={
          <RequireAuth>
            <MissingViewPage />
          </RequireAuth>
        }
      />
      <Route
        path={routes.scoutingAbsolutePath}
        element={
          <RequireAuth>
            <ScoutingPage />
          </RequireAuth>
        }
      />
      <Route
        path={routes.sportBudgetAbsolutePath}
        element={
          <RequireAuth>
            <SportBudgetPage />
          </RequireAuth>
        }
      />
    </Route>

    <Route path={routes.common} element={<Layout fullScreen />}>
      <Route
        path={routes.scoutingTask}
        element={
          <RequireAuth>
            <ScoutingTaskPage />
          </RequireAuth>
        }
      />
      <Route
        path={routes.playerAbsolutePath}
        element={
          <RequireAuth>
            <PlayerPage />
          </RequireAuth>
        }
      />
    </Route>
  </Routes>
);

export default Router;
