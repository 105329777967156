import { graphql } from 'gql';

import { client } from './client';
import * as GQLTypes from 'gql/graphql';

export type {
  ExerciseCreateInput,
  ExerciseUpdateInput,
  ExerciseDeleteInput,
  ExerciseFragment,
  ExerciseShortFragment,
  ExerciseTagFragment,
} from 'gql/graphql';

// FRAGMENTS //////////////////////////////////////////////////////////////////

graphql(`
  fragment Exercise on Exercise {
    id
    name
    description
    tags {
      ...ExerciseTag
    }
    attachments {
      ...Attachment
    }
    video {
      ...VideoShort
    }
  }
`);

graphql(`
  fragment ExerciseShort on Exercise {
    id
    name
    createdBy {
      name
    }
    tags {
      ...ExerciseTag
    }
  }
`);

graphql(`
  fragment ExerciseTag on ExerciseTag {
    id
    name
    category
  }
`);

// QUERIES ////////////////////////////////////////////////////////////////////

const EXERCISES_QUERY = graphql(`
  query Exercises($orgId: ID!, $query: String, $tagIds: [ID!], $after: String) {
    exercises(orgId: $orgId, query: $query, tagIds: $tagIds, after: $after, first: 100) {
      ... on ExerciseConnection {
        edges {
          node {
            ...ExerciseShort
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      ... on ExercisesQueryDomainError {
        code
        message
      }
    }
  }
`);

const EXERCISE_QUERY = graphql(`
  query Exercise($orgId: ID!, $ids: [ID!]) {
    exercises(orgId: $orgId, ids: $ids) {
      ... on ExerciseConnection {
        nodes {
          ...Exercise
        }
      }
      ... on ExercisesQueryDomainError {
        code
        message
      }
    }
  }
`);

const EXERCISE_TAGS_QUERY = graphql(`
  query ExerciseTags($orgId: ID!, $query: String, $categories: [ExerciseTagCategoriesEnum!]) {
    exerciseTags(orgId: $orgId, query: $query, categories: $categories) {
      ... on ExerciseTagConnection {
        nodes {
          ...ExerciseTag
        }
      }
      ... on ExerciseTagsQueryDomainError {
        code
        message
      }
    }
  }
`);

// MUTATIONS //////////////////////////////////////////////////////////////////

const CREATE_EXERCISE_MUTATION = graphql(`
  mutation CreateExercise($input: ExerciseCreateInput!) {
    exercise {
      create(input: $input) {
        exercise {
          ...Exercise
        }
        error {
          code
          message
        }
      }
    }
  }
`);

const UPDATE_EXERCISE_MUTATION = graphql(`
  mutation UpdateExercise($input: ExerciseUpdateInput!) {
    exercise {
      update(input: $input) {
        exercise {
          ...Exercise
        }
        error {
          code
          message
        }
      }
    }
  }
`);

const DELETE_EXERCISE_MUTATION = graphql(`
  mutation DeleteExercise($input: ExerciseDeleteInput!) {
    exercise {
      delete(input: $input) {
        result
        error {
          code
          message
        }
      }
    }
  }
`);

const CREATE_EXERCISE_TAG_MUTATION = graphql(`
  mutation CreateExerciseTag($input: ExerciseTagCreateInput!) {
    exerciseTag {
      create(input: $input) {
        exerciseTag {
          ...ExerciseTag
        }
        error {
          code
          message
        }
      }
    }
  }
`);

const UPDATE_EXERCISE_TAG_MUTATION = graphql(`
  mutation UpdateExerciseTag($input: ExerciseTagUpdateInput!) {
    exerciseTag {
      update(input: $input) {
        exerciseTag {
          ...ExerciseTag
        }
        error {
          code
          message
        }
      }
    }
  }
`);

const DELETE_EXERCISE_TAG_MUTATION = graphql(`
  mutation DeleteExerciseTag($input: ExerciseTagDeleteInput!) {
    exerciseTag {
      delete(input: $input) {
        result
        error {
          code
          message
        }
      }
    }
  }
`);

const QUERIES = {
  queryExercises: async (variables: GQLTypes.QueryExercisesArgs) => {
    const result = await client.query({
      query: EXERCISES_QUERY,
      variables,
    });

    if (result.data.exercises.__typename === 'ExercisesQueryDomainError') {
      return Promise.reject(result.data.exercises);
    }

    return result.data.exercises;
  },

  queryExercise: async (variables: GQLTypes.QueryExercisesArgs) => {
    const result = await client.query({
      query: EXERCISE_QUERY,
      variables,
    });

    if (result.data.exercises.__typename === 'ExercisesQueryDomainError') {
      return Promise.reject(result.data.exercises);
    }

    return result.data.exercises.nodes[0];
  },

  queryExerciseTags: async (variables: GQLTypes.QueryExerciseTagsArgs) => {
    const result = await client.query({
      query: EXERCISE_TAGS_QUERY,
      variables,
    });

    if (result.data.exerciseTags.__typename === 'ExerciseTagsQueryDomainError') {
      return Promise.reject(result.data.exerciseTags);
    }

    return result.data.exerciseTags.nodes;
  },
};

const MUTATIONS = {
  createExercise: async (input: GQLTypes.ExerciseCreateInput) => {
    const result = await client.mutate({
      mutation: CREATE_EXERCISE_MUTATION,
      variables: { input },
    });

    if (result.data.exercise.create.error) {
      return Promise.reject(result.data.exercise.create.error);
    }

    return result.data.exercise.create.exercise;
  },

  updateExercise: async (input: GQLTypes.ExerciseUpdateInput) => {
    const result = await client.mutate({
      mutation: UPDATE_EXERCISE_MUTATION,
      variables: { input },
    });

    if (result.data.exercise.update.error) {
      return Promise.reject(result.data.exercise.update.error);
    }

    return result.data.exercise.update.exercise;
  },

  deleteExercise: async (input: GQLTypes.ExerciseDeleteInput) => {
    const result = await client.mutate({
      mutation: DELETE_EXERCISE_MUTATION,
      variables: { input },
    });

    if (result.data.exercise.delete.error) {
      return Promise.reject(result.data.exercise.delete.error);
    }

    return result.data.exercise.delete.result;
  },

  createExerciseTag: async (input: GQLTypes.ExerciseTagCreateInput) => {
    const result = await client.mutate({
      mutation: CREATE_EXERCISE_TAG_MUTATION,
      variables: { input },
    });

    if (result.data.exerciseTag.create.error) {
      return Promise.reject(result.data.exerciseTag.create.error);
    }

    return result.data.exerciseTag.create.exerciseTag;
  },

  updateExerciseTag: async (input: GQLTypes.ExerciseTagUpdateInput) => {
    const result = await client.mutate({
      mutation: UPDATE_EXERCISE_TAG_MUTATION,
      variables: { input },
    });

    if (result.data.exerciseTag.update.error) {
      return Promise.reject(result.data.exerciseTag.update.error);
    }

    return result.data.exerciseTag.update.exerciseTag;
  },

  deleteExerciseTag: async (input: GQLTypes.ExerciseTagDeleteInput) => {
    const result = await client.mutate({
      mutation: DELETE_EXERCISE_TAG_MUTATION,
      variables: { input },
    });

    if (result.data.exerciseTag.delete.error) {
      return Promise.reject(result.data.exerciseTag.delete.error);
    }

    return result.data.exerciseTag.delete.result;
  },
};

const exercisesService = {
  ...QUERIES,
  ...MUTATIONS,
};

export default exercisesService;
