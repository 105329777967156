import { Telegram } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { bindDialog, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import styled from '@emotion/styled';

import { MobileMenuItem } from './MobileMenu';
import TelegramLoginButton from '../TelegramLoginButton';
import useNotificationsStore from 'features/Notifications/Notifications.model';

const LinkTelegram = () => {
  const popupState = usePopupState({ variant: 'dialog' });
  const notification = useNotificationsStore();
  const smallScreen = useMediaQuery('(max-width:768px)');

  const handleLinkTelegram = () => {
    popupState.close();
    notification.send({ title: 'Аккаунт успешно привязан к Telegram', type: 'success' });
  };

  const handleError = (error?: { code: string; message: string }) => {
    if (error?.code === 'telegram_already_used') {
      notification.send({ title: 'Этот Telegram-аккаунт уже используется', type: 'error' });
    } else if (error?.code === 'telegram_not_found') {
      notification.send({ title: 'Не удалось привязать аккаунт к Telegram', type: 'error' });
    }
  };

  return (
    <>
      {smallScreen ? (
        <MobileMenuItem {...bindTrigger(popupState)}>
          <Telegram color="primary" />
          <MobileTriggerLabel>Привязать Telegram</MobileTriggerLabel>
        </MobileMenuItem>
      ) : (
        <Trigger {...bindTrigger(popupState)}>
          <ListItemIcon>
            <Telegram color="primary" />
          </ListItemIcon>
          <ListItemText primary="Привязать Telegram" />
        </Trigger>
      )}

      <Dialog {...bindDialog(popupState)} fullWidth maxWidth="xs">
        <DialogTitle>Привязать Telegram</DialogTitle>
        <DialogContent>
          <Description>Нажмите на кнопку ниже для привязки вашего аккаунта к Telegram.</Description>
          {popupState.isOpen && (
            <TelegramLoginButton onLogin={handleLinkTelegram} onError={handleError} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LinkTelegram;

const Trigger = styled(MenuItem)`
  border-bottom: 1px solid var(--neutral-border);

  span {
    color: var(--primary);
    font-weight: 500;
  }
`;

const MobileTriggerLabel = styled.span`
  color: var(--primary);
  font-weight: 500;
`;

const Description = styled.p`
  margin: 0;
  margin-bottom: var(--space-md);
  font-size: var(--fz-sm);
  color: var(--text-subtle);
`;
